import React from 'react';
import { HashLink } from 'react-router-hash-link';

const Copyright = () => {
    return (
        <div className="d-md-flex align-items-center justify-content-between">
            <ul className="order-md-last">
                <li>
                    <HashLink to='/mentions-legales#'>
                        Mention légales
                    </HashLink>
                </li>
            </ul>
            <p>
                © {new Date().getFullYear()}{" "}
                Codalys AG
            </p>
        </div>
    );
};

export default Copyright;
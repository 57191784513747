import React from 'react';
import Helmet from "react-helmet";
import Contact from '../components/contact/Contact';
import Footer from '../components/footer/Footer';
import Header from '../components/header/Header';
import Services from '../components/services/Services';
import Mission from '../components/mission/Mission';
import Hero from '../components/hero/Hero';

const Home = () => {
    return (
        <div className='main-page-wrapper'>
            <Helmet>
                <title>Partenaire de votre digitalisation • Codalys</title>
                <link rel="canonical" href={window.location.href} />
                <meta name="description" content="Envie d'une application moderne, ludique et adaptée à votre métier ? Nous vous accompagnons dans la conception et le déploiement de vos outils numériques" />
            </Helmet>
            <div className='landing-banner bg-transparent'>
                <Header /> 
                <Hero />
            </div>
            <Services />
            <Mission />
            <Contact />
            <Footer />
        </div>
    );
};

export default Home;
import { useEffect } from 'react'
import Helmet from "react-helmet";
import Routes from "./router/Routes";
import ScrollToTop from "./components/scroll/ScrollToTop";

import AOS from "aos";
import "aos/dist/aos.css";

const App = () => {
  useEffect(() => {
    AOS.init({
      duration: 1200,
    });
  }, []);
  window.addEventListener("load", AOS.refresh);

  const url = window.location.href;

  return (
    <>
      <Helmet>
        <meta property="og:title" content="Codalys • Partenaire de votre digitalisation" />
        <meta property="og:type" content="website" />
        <meta property="og:description" content="Envie d'une application moderne, ludique et adaptée à votre métier ? Nous vous accompagnons dans la conception et le déploiement de vos outils numériques" />
        <meta property="og:url" content={`${url}`} />
        <meta property="og:image" content={`${url}images/social/og_img.jpg`} />
        <meta property="og:image:type" content="image/jpg" />
        <meta property="og:image:width" content="1200"/>
        <meta property="og:image:height" content="630"/>
        <meta property="og:site_name" content="codalys" />
        <meta property="og:local" content="fr_FR" />

        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:title" content="Codalys • Partenaire de votre digitalisation" />
        <meta name="twitter:description" content="Codalys vous accompagne dans la conception et le déploiement de vos outils numériques" />
        <meta name="twitter:image" content={`${url}images/social/OG_img.jpg`} />
      </Helmet>
      <ScrollToTop />
      <Routes />
    </>
  );
}

export default App;
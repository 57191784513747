import React from 'react';

const Welcome = () => {
    return (
        <div className="fancy-hero-one" id="index">
            <div className="container">
                <div className="row">
                    <div className="col-xl-9 col-lg-11 m-auto">
                        <h1 className="font-rubik">
                            Créons ensemble vos outils de demain
                        </h1>
                        <h2>
                            Notre expérience au service de vos projets digitaux
                        </h2>
                    </div>
                    <div className="col-xl-9 col-lg-11 m-auto">
                        <a className="theme-btn-two trial-button mt-50  mb-50 md-mt-30" href="#services">Découvrez nos services</a>
                    </div>
                </div>
            </div>
            <div className="bubble-one"></div>
            <div className="bubble-two"></div>
            <div className="bubble-three"></div>
            <div className="bubble-four"></div>
            <div className="bubble-five"></div>
            <div className="bubble-six"></div>
        </div>
    );
};

export default Welcome;
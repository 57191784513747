import React from 'react';
import { HashLink } from 'react-router-hash-link';
import Copyright from './Copyright';
import logo from "../../assets/images/logo/codalys/logo-1.svg"


const Footer = () => {
    return (
        <footer className="theme-footer-eight mt-50 mb-20">
            <div className="top-footer">
                <div className="container">
                    <div className="row justify-content-end">
                        <div className="col-lg-3 col-md-12 footer-list">
                            <HashLink to='/#' className="logo" aria-label="Revenir à la page d'accueil">
                                <img src={logo} alt="Accueil Codalys" height={30} width={119} />
                            </HashLink>
                        </div>
                        <div className="col-lg-3 col-md-4 footer-list">
                            <ul>
                                <li>
                                    <HashLink to="/#services">Gestion de projet</HashLink>
                                </li>
                                <li>
                                    <HashLink to="/#services">Développement</HashLink>
                                </li>
                                <li>
                                    <HashLink to="/#services">Tierce Maintenance Applicative</HashLink>
                                </li>
                                <li>
                                    <HashLink to="/#services">Formation</HashLink>
                                </li>
                            </ul>
                        </div>
                        <div className="col-lg-3 col-md-4 footer-list">
                            <ul>
                                <li>
                                    {/* <HashLink to="/#news">Actualités & blog</HashLink> */}
                                </li>
                                <li>
                                    <HashLink to="/#contact">Contact</HashLink>
                                </li>
                            </ul>
                        </div>
                        <div className="col-lg-3 col-md-4 footer-list">
                            <ul>
                                <li>
                                    <a href="https://goo.gl/maps/DFBjzpu6cTLtZCnu5" className="address" target="_blank" rel="noreferrer">
                                        Parc racine²
                                        <br />
                                        2 rue Héraclès
                                        <br />
                                        59650 Villeneuve-d'Ascq
                                    </a>
                                </li>
                                <li>
                                    <a href="tel:+33643903922" className="mobile-num">
                                        +33 (0)6 43 90 39 22
                                    </a>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
            <div className="container">
                <div className="bottom-footer-content">
                    <Copyright />
                </div>
            </div>
        </footer>
    );
};

export default Footer;
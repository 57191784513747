import React from 'react';
import "./navbar.scss";
import { Link } from "react-scroll";

const Navbar = () => {
    return (
        <nav id="feature-menu" className="landing-mobile_menu navbar navbar-expand-sm order-md-2">
            <div className="m-auto nav-container">
                <div id="navbarSupportedContent">
                    <ul className="navbar-nav" >
                        <li className="nav-item" >
                            <Link activeClass="active" to="services" spy={true} className="nav-link" href='#' hrefLang={'fr'}>Services</Link>
                        </li>
                        <li className="nav-item">
                            <Link activeClass="active" to="mission" spy={true} className="nav-link" href='#' hrefLang={'fr'}>Mission</Link>
                        </li>
                        <li className="nav-item">
                            <Link activeClass="active" to="contact" spy={true} className="nav-link" href='#' hrefLang={'fr'}>Contact</Link>
                        </li>
                    </ul>
                </div>
            </div>
        </nav>
    );
};

export default Navbar;
import React from 'react';

const socialContent = [
    {
        icon: "fa-facebook",
        link: "https://www.facebook.com/codalys/",
        text: "Codalys sur Facebook",
    },
    {
        icon: "fa-twitter",
        link: "https://twitter.com/codalys_ag/",
        text: "Codalys sur twitter",
    },
    {
        icon: "fa-linkedin",
        link: "https://www.linkedin.com/company/codalys-ag/",
        text: "Codalys sur linkedin",
    },
];

const Contact = () => {
    return (
        <>
            <div className="fancy-hero-one" id="contact">
                <div className="container">
                    <div className="row">
                        <div className="m-auto">
                            <h3>
                                <span>
                                    Nous contacter{" "}
                                    <img src="images/shape/line-shape-5.svg" alt="shape" />
                                </span>
                            </h3>
                            <p>
                                Envie d'échanger sur votre projet ?
                                <br /><br />
                                Passez nous voir au parc Racine² , <br />
                                écrivez-nous ou passez-nous un coup de fil 😉
                            </p>
                        </div>
                    </div>
                </div>
            </div>
            <div className="contact-us-light" >
                <div className="container">
                    <div className="row justify-content-center">
                        <div className="col-lg-4 col-sm-6">
                            <div className="address-info">
                                <div className="icon d-flex align-items-end">
                                    <img src="images/icon/44.svg" alt="icon" height={76} width={60} />
                                </div>
                                <p className="font-rubik pt-30">
                                    <a href="https://goo.gl/maps/DFBjzpu6cTLtZCnu5" className="address" target="_blank" rel="noreferrer">
                                        Parc racine²
                                        <br />
                                        2 rue Héraclès
                                        <br />
                                        59650 Villeneuve-d'Ascq
                                    </a>
                                </p>
                            </div>{" "}
                            {/* /.address-info  */}
                        </div>
                        {/* End .col */}

                        <div className="col-lg-4 col-sm-6">
                            <div className="address-info">
                                <div className="icon d-flex align-items-end">
                                    <img src="images/icon/45.svg" alt="icon" height={68} width={75} />
                                </div>
                                <p className="font-rubik pt-30">
                                    <a href="mailto:hello@codalys.fr" target="_self">
                                        hello@codalys.fr
                                    </a>
                                    <br />
                                    <a href="tel:+33643903922">
                                        +33 (0)6 43 90 39 22
                                    </a>
                                </p>
                            </div>{" "}
                            {/* /.address-info  */}
                        </div>
                        {/* End .col */}

                        <div className="col-lg-4 col-sm-6">
                            <div className="address-info">
                                <div className="icon d-flex align-items-end">
                                    <img src="images/icon/46.svg" alt="icon" height={75} width={66} />
                                </div>
                                <ul className="d-flex justify-content-center pt-30">
                                    {socialContent.map((val, i) => (
                                        <li key={i}>
                                            <a href={val.link} target="_blank" rel="noreferrer" aria-label={val.text}>
                                                <i className={`fa ${val.icon}`}></i>
                                            </a>
                                        </li>
                                    ))}
                                </ul>
                            </div>
                            {/* /.address-info  */}
                        </div>
                        {/* End .col */}
                    </div>
                </div>
            </div>
        </>
    );
};

export default Contact;
import React from "react";
import { Link } from "react-router-dom";
import "./services.scss";

const features = [
    {
        bgColor: "#ffebdb",
        icon: "70",
        title: "Gestion de projet",
        descriptions:
            "Du recueil de votre besoin jusqu'à la conduite du changement, nous apportons notre expertise pour maximiser votre création de valeur.",
        dealyAnimation: "100",
    },
    {
        bgColor: "#e0f8f8",
        icon: "67",
        title: "Développement",
        descriptions:
            "Experts des technologies Microsoft, nous réalisons des applications modernes performantes et facilement maintenables au service de vos projets.",
        dealyAnimation: "100",
    },
    {
        bgColor: "#e6fbff",
        icon: "108",
        title: "Tierce Maintenance Applicative",
        descriptions:
            "Afin de vivre une expérience sans friction, nous assurons les opérations de maintenance et de mise à jour de vos outils digitaux.",
        dealyAnimation: "100",
    },
    {
        bgColor: "#f7edff",
        icon: "graduate-cap-svgrepo-com",
        title: "Formation",
        descriptions:
            "Pour faciliter l'appropriation des nouveaux outils et augmenter l'efficacité de vos process métiers, nous formons vos équipes aux nouveaux usages.",
        dealyAnimation: "100",
    },
];

const Services = () => {
    return (

        <div className="fancy-feature-seven" id="services">
            <div className="container">
                <div className="row">
                    <div className="col-lg-8 m-auto">
                        <div className="title-style-four text-center pb-50 md-pb-20">
                            <h3>
                                <span>
                                    Nos Services{" "}
                                    <img src="images/shape/line-shape-5.svg" alt="shape" />
                                </span>
                            </h3>
                        </div>
                    </div>
                </div>
                <div className="row">
                    {features.map((item, i) => (
                        <div
                            className="col-md-6"
                            data-aos="fade-up"
                            data-aos-delay={item.dealyAnimation}
                            key={i}
                        >
                            <div className="block-style-thirtyTwo d-flex">
                                <div
                                    className="icon d-flex align-items-center justify-content-center"
                                    style={{ background: item.bgColor }}
                                >
                                    <img src={`images/icon/${item.icon}.svg`} alt="icon" width={30} height={30} />
                                </div>
                                <div className="text">
                                    <h4>{item.title}</h4>
                                    <p>{item.descriptions}</p>
                                    {/* <Link to="/service-details" className="tran3s">
                                        <img src="images/icon/182.svg" alt="icon" width={35} height={15} />
                                    </Link> */}
                                </div>
                            </div>
                        </div>
                    ))}
                </div>
            </div>
        </div>
    );
};

export default Services;
import React from 'react';
import Helmet from "react-helmet";
import Footer from '../components/footer/Footer';
import Header from '../components/header/Header';
import { HashLink } from 'react-router-hash-link';
import { Link } from "react-scroll";


const Terms = () => {
    return (
        <div className="doc-container">
            <Helmet>
                <title>Mentions Légales • Codalys</title>
                <link rel="canonical" href={window.location.href} />
                <meta name="description" content="Découvrez nos mentions légales" />
                <meta name="keywords" content="Mentions légales" />
            </Helmet>
            <Header />
            <div className="fancy-hero-seven">
                <div className="container">
                    <div className="row">
                        <div className="col-xl-8 col-lg-10 m-auto">
                            <h1 className="font-rubik">Mentions légales</h1>
                        </div>
                    </div>
                </div>
            </div>
            <div className="terms_and_policy">
                <div className="container">

                    <div className="row align-items-start">
                        <div className="col-lg-4 sidenav-sticky">
                            <ul className="nav nav-tabs">
                                <li className="nav-item">
                                    <Link activeClass="active" to="general-informations" spy={true} className="nav-link" offset={-600} hrefLang={'fr'}>Informations générales</Link>
                                </li>
                                <li className="nav-item">
                                    <Link activeClass="active" to="terms-of-service" spy={true} className="nav-link" offset={-80} hrefLang={'fr'}>Conditions générales d’utilisation</Link>
                                </li>
                                <li className="nav-item">
                                    <Link activeClass="active" to="privacy" spy={true} className="nav-link" offset={-80} hrefLang={'fr'}>Données personnelles</Link>
                                </li>
                                <li className="nav-item">
                                    <Link activeClass="active" to="cookies" spy={true} className="nav-link" offset={-80} hrefLang={'fr'}>Cookies</Link>
                                </li>
                                <li className="nav-item">
                                    <Link activeClass="active" to="hyperlinks" spy={true} className="nav-link" offset={-80} hrefLang={'fr'}>Liens hypertextes</Link>
                                </li>
                                <li className="nav-item">
                                    <Link activeClass="active" to="limit-of-liability" spy={true} className="nav-link" offset={-80} hrefLang={'fr'}>Limite de responsabilité</Link>
                                </li>
                                <li className="nav-item">
                                    <Link activeClass="active" to="applicable-law" spy={true} className="nav-link" offset={-200} hrefLang={'fr'}>Droit applicable</Link>
                                </li>
                            </ul>
                        </div>

                        <div className="col-lg-8">
                            <div className="tab-content ">
                                <div id="general-informations">
                                    <h3 className="font-gilroy-bold">Informations générales</h3>
                                    <div className="update-date">DERNIERE MISE A JOUR: 04/08/2022</div>
                                    <p>
                                        Conformément aux dispositions des articles 6-III et 19 de la loi n° 2004-575 du 21 juin 2004 pour la confiance dans l’économie numérique,
                                        il est porté à la connaissance des utilisateurs et visiteurs du site www.codalys.fr les présentes mentions légales.
                                        <br />
                                        <br />
                                        La connexion, l’utilisation et l’accès à ce site implique acceptation intégrale et sans réserve des présentes mentions légales.
                                    </p>
                                    <p>
                                        Le site www.codalys.fr est la propriété de la société <b>Codalys AG</b> :
                                        <br /><br />
                                        <ul>
                                            <li>
                                                <b>Forme juridique</b> : SASU au capital de 1 000€
                                            </li>
                                            <li>
                                                <b>SIRET</b> : 91499023900016 -  RCS Lille Métropole B 914 990 239
                                            </li>
                                            <li>
                                                <b>TVA Intracommunautaire</b> : FR 61 489 800 805
                                            </li>
                                            <li>
                                                <b>Siège social</b> : 2 rue Héraclès – 59650 Villeneuve-d'Ascq - France
                                            </li>
                                            <li>
                                                <b>Directeur de publication</b> : Monsieur Anthony Goudemez - hello@codalys.fr
                                            </li>
                                        </ul>
                                    </p>
                                    <p>
                                        L’hébergement du site www.codalys.fr est assurée par la société <b>OVH SAS</b> : <br />
                                        2 rue Kellermann – BP 80157 59053 ROUBAIX CEDEX 1
                                    </p>
                                </div>
                                <div id="terms-of-service" className="pt-60">
                                    <h3 className="font-gilroy-bold">Conditions générales d’utilisation</h3>
                                    <div className="update-date">DERNIERE MISE A JOUR: 04/08/2022</div>
                                    <p>
                                        L’utilisation du site www.codalys.fr implique l’acceptation pleine et entière des conditions générales d’utilisation ci-présentes.
                                        <br />
                                        Ces conditions d’utilisation sont susceptibles d’être modifiées ou complétées à tout moment, les utilisateurs et visiteurs du site www.codalys.fr sont donc invités à les consulter de manière régulière.
                                        <br />
                                        Ce site est normalement accessible à tout moment aux utilisateurs et visiteurs.
                                        <br />
                                        Une interruption pour raison de maintenance peut toutefois être décidée par la société Codalys AG, qui s'efforcera alors de communiquer préalablement aux utilisateurs les dates et heures de l'intervention.
                                        <br />
                                        La société Codalys AG ne pourra être tenue responsable de l’indisponibilité du site et des conséquences potentielles pour l’utilisateur, pour quelque motif que cela soit.
                                    </p>
                                </div>
                                <div id="privacy" className="pt-60">
                                    <h3 className="font-gilroy-bold">Données personnelles</h3>
                                    <div className="update-date">DERNIERE MISE A JOUR: 04/08/2022</div>
                                    <p>
                                        Conformément à la Loi Informatique et Libertés du 06/01/78, art. 27, vous disposez d'un droit d'accès et de rectification des informations nominatives vous concernant.
                                        Codalys AG ne collecte pas d'information susceptible d'être revendu à des tiers.
                                        Le droit d'accès à vos données personnelles est à exercer à l'adresse suivante : Codalys AG, 2 rue Héraclès – 59650 Villeneuve-d'Ascq - France
                                    </p>
                                </div>
                                <div id="cookies" className="pt-60">
                                    <h3 className="font-gilroy-bold">Cookies</h3>
                                    <div className="update-date">DERNIERE MISE A JOUR: 04/08/2022</div>
                                    <p>
                                        La navigation sur le site www.codalys.fr est susceptible de provoquer l'installation de cookie(s) sur l'ordinateur de l'utilisateur.
                                        <br />
                                        Un cookie est un fichier de petite taille, qui ne permet pas l'identification de l'utilisateur, mais qui enregistre des informations relatives à la navigation d'un ordinateur sur un site.
                                        <br />
                                        Les données ainsi obtenues visent à faciliter la navigation ultérieure sur le site, et ont également vocation à permettre diverses mesures de fréquentation.
                                        <br />
                                        Le refus d'installation d'un cookie peut entraîner l'impossibilité d'accéder à certains services.
                                        <br />
                                        L'utilisateur peut toutefois configurer son ordinateur de la manière suivante, pour refuser l'installation des cookies :

                                        <ul>
                                            <li>
                                                Sous Internet Explorer : onglet outil (pictogramme en forme de rouage en haut a droite) / options internet.
                                                Cliquez sur Confidentialité et choisissez Bloquer tous les cookies. Validez sur Ok.
                                            </li>
                                            <li>
                                                Sous Firefox : en haut de la fenêtre du navigateur, cliquez sur le bouton Firefox, puis aller dans l'onglet
                                                Options. Cliquer sur l'onglet Vie privée. Paramétrez les Règles de conservation sur : utiliser les paramètres
                                                personnalisés pour l'historique. Enfin décochez-la pour désactiver les cookies.
                                            </li>
                                            <li>
                                                Sous Safari : Cliquez en haut à droite du navigateur sur le pictogramme de menu (symbolisé par un rouage).
                                                Sélectionnez Paramètres. Cliquez sur Afficher les paramètres avancés. Dans la section "Confidentialité",
                                                cliquez sur Paramètres de contenu. Dans la section "Cookies", vous pouvez bloquer les cookies.
                                            </li>
                                            <li>
                                                Sous Chrome : Cliquez en haut à droite du navigateur sur le pictogramme de menu (symbolisé par trois points
                                                verticaux). Sélectionnez 'Paramètres'. Cliquez sur 'Confidentialité et sécurité'. Dans la section
                                                "Cookies et autres données des sites", vous pouvez bloquer les cookies.
                                            </li>
                                        </ul>
                                    </p>
                                </div>
                                <div id="hyperlinks" className="pt-60">
                                    <h3 className="font-gilroy-bold">Liens hypertextes</h3>
                                    <div className="update-date">DERNIERE MISE A JOUR: 04/08/2022</div>
                                    <p>
                                        Le site peut contenir des liens hypertextes allant vers d’autres sites.
                                        <br /><br />
                                        Si le visiteur / l’utilisateur du site www.codalys.fr navigue sur l’un de ces sites, la société Codalys AG l’invite à prendre connaissance des mentions légales de ces derniers.
                                        <br /><br />
                                        La société Codalys AG ne peut en aucun cas être tenu pour responsable pour la mise à disposition des liens hypertextes vers ces sites
                                        et ne prend aucun engagement concernant le contenu, le fonctionnement, les politiques appliquées par d’autres sociétés.
                                        <br /><br />
                                        La mise en place d’un lien vers le site www.codalys.fr est soumise à une approbation préalable de la société Codalys AG.
                                    </p>
                                </div>
                                <div id="limit-of-liability" className="pt-60">
                                    <h3 className="font-gilroy-bold">Limite de responsabilité</h3>
                                    <div className="update-date">DERNIERE MISE A JOUR: 04/08/2022</div>
                                    <p>
                                        Codalys AG ne pourra en aucun cas être tenue responsable des dommages directs ou indirects causés au matériel de l'utilisateur, lors de
                                        l'accès au site www.codalys.fr ou de l'accès via ce site à d'autres sites et leur utilisation.
                                        Des espaces interactifs peuvent être mis à la disposition des utilisateurs.
                                        Codalys AG se réserve le droit de supprimer, sans mise en demeure préalable, tout contenu déposé dans cet espace qui
                                        contreviendrait à la législation applicable en France, en particulier aux dispositions relatives à la protection des
                                        données. Le cas échéant, Codalys AG se réserve également la possibilité de mettre en cause la responsabilité civile et/ou
                                        pénale de l'utilisateur, notamment en cas de message à caractère raciste, injurieux, diffamant, ou pornographique,
                                        quel que soit le support utilisé (texte, photographie…)
                                    </p>
                                </div>
                                <div id="applicable-law" className="pt-60">
                                    <h3 className="font-gilroy-bold">Droit applicable</h3>
                                    <div className="update-date">DERNIERE MISE A JOUR: 04/08/2022</div>
                                    <p>
                                        Tout litige en relation avec l’utilisation du site www.codalys.fr est soumis au droit français.
                                        Il est fait attribution exclusive de juridiction aux tribunaux compétents de Lille.
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="fancy-short-banner-two pt-40">
                    <div className="container">
                        <div className="content-wrapper">
                            <div className="bg-wrapper d-lg-flex align-items-center justify-content-between">
                                <h3 className="font-gilroy-bold">Besoin d'une précision ?</h3>
                                <HashLink to="/#contact">Contactez nous</HashLink>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <Footer />
        </div>
    );
};

export default Terms;
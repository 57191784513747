import React from 'react';

const Mission = () => {
    return (
        <div className="fancy-text-block-one" id="mission">
            <div className="container">
                <div className="row">
                    <div className="col-lg-8 m-auto">
                        <div className="title-style-four text-center pb-150">
                            <h3>
                                <span>
                                    Notre Mission{" "}
                                    <img src="images/shape/line-shape-5.svg" alt="shape" />
                                </span>
                            </h3>
                        </div>
                    </div>
                </div>
                <div className="row align-items-center">
                    <div className="col-lg-6 col-md-7 col-sm-8 m-auto">
                        <img src="/images/codalys/anthony_goudemez.webp" alt="media" className="rounded-circle" height={288} width={288} />
                    </div>
                    <div className="col-lg-6">
                        <div className="quote-wrapper">
                            <blockquote className="font-rubik">
                                Vous aider dans votre digitalisation en développant des solutions innovantes et ludiques répondant à vos besoins opérationnels.
                            </blockquote>
                            <h6>
                                Anthony Goudemez <span>- Fondateur & CEO - </span>
                                <span>
                                    <a href='https://www.linkedin.com/in/anthonygoudemez/' target="_blank" rel="noreferrer" aria-label='linkedin Anthony Goudemez'>
                                        <i className='fa fa-linkedin' />
                                    </a>
                                </span>
                            </h6>

                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Mission;
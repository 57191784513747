import React, { useState } from 'react';
import Navbar from './Navbar';
import logo from "../../assets/images/logo/codalys/logo-1.svg"
import logoWhite from "../../assets/images/logo/codalys/logo-4.svg"
import { HashLink } from 'react-router-hash-link';
import "./header.scss";

const Header = () => {

    const [click, setClick] = useState(false);
    const handleClick = () => setClick(!click);
    const [navbar, setNavbar] = useState(false);

    const changeBackground = () => {
        if (window.scrollY >= 68) {
            setNavbar(true);
        } else {
            setNavbar(false);
        }
    };

    window.addEventListener("scroll", changeBackground);

    return (
        <div className={navbar ? "landing-menu fixed" : "landing-menu"} id="home">
            <div className="container-fluid">
                <div className="d-flex align-items-center justify-content-between">
                    <div className="logo order-md-1">
                        <HashLink to='/#'>
                            <img src={logo} alt="Accueil Codalys" height={40} width={159} />
                        </HashLink>
                    </div>
                    <Navbar />
                </div>
                <div className="mega-menu-wrapper">
                    <div className="mob-header multi-mob-header">
                        <button className="toggler-menu" onClick={handleClick} aria-label="hamburger">
                            <div className={click ? "active" : ""}>
                                <span></span>
                                <span></span>
                                <span></span>
                            </div>
                        </button>
                    </div>
                </div>
                <div className={click ? "mobile-menu  menu-open" : "mobile-menu"}>
                    <div className="logo order-md-1">
                        <HashLink to='/#' onClick={handleClick}>
                            <img src={logoWhite} alt="brand" height={173} width={250} />
                        </HashLink>
                    </div>
                    <ul className="navbar-nav">
                        <li className="nav-item">
                            <HashLink to='/#services' className="nav-link" onClick={handleClick}>
                                Services
                            </HashLink>
                        </li>
                        <li className="nav-item">
                            <HashLink to='/#mission' className="nav-link" onClick={handleClick}>
                                Mission
                            </HashLink>
                        </li>
                        <li className="nav-item">
                            <HashLink to='/#contact' className="nav-link" onClick={handleClick}>
                                Contact
                            </HashLink>
                        </li>
                    </ul>
                </div>
            </div>
        </div>
    );
};

export default Header;